import React, { Component } from "react";

class GoogleMapStyle extends Component {
  render() {
    return (
      // Important! Always set the container height explicitly

      <div className="google-map-style-1">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14476.719682969644!2d91.8722995!3d24.8918438!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3751abc8803874c3%3A0x8f40901a93216e51!2sKarim%20Ullah%20Market!5e0!3m2!1sen!2sbd!4v1707262453317!5m2!1sen!2sbd"
          width="100%"
          height="100%"
          style={{ borderRadius: "10px", position: "relative", border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;
