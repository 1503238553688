import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const testimonialData = [
    {
        image: 'testimonial-01',
        name: 'Kamrul Hamid',
        designation: 'CEO of Modhuful Fodd Products LTD ',
        location: 'Sylhet,Bangladesh',
        description: '“We have been working with Murad since 2015. Over these last few years, our experience with them has been really great. Murad has always been a huge creative support for us.alos Thanks to LWM Teams„',
       
    },
    {
        image: 'testimonial-02',
        name: 'Mohsin Kamal',
        designation: 'Manager, Halalhutbd',
        location: 'Bangladesh', 
        description: '“This is what we actually needed, an up to the mark web developers & web maintenance specialists. Very reliable and efficient group of people to work with, i wish them all the best for LWM Teams.„',
      
    },
    {
        image: 'testimonial-03',
        name: 'Masud Ahmed',
        designation: 'Director,Swapna Blood Donation and Social Welfare Organization',
        location: 'Sylhet, Bangladesh',
        description: '“My journey of working with Murad has been wonderful.I thank him for helping us by making such wonderful Android apps and congratulate him on behalf of our organization and wish him all the best.„',
    },
]


const TestimonialOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {testimonialData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-box-card ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/testimonial/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <p className="description">{data.description}</p>
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TestimonialOne;
