import React from 'react';
import { FiPhone, FiMapPin, FiFile } from "react-icons/fi";


const AboutFive = () => {
    return (
        <div className="about-style-5 rn-section-gapBottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="row row--0 about-wrapper align-items-center theme-shape">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img src="./images/about/about-5.png" alt="About Images" />
                                </div>
                            </div>
                            <div className="col-lg-6 mt_md--30 mt_sm--30">
                                <div className="content">
                                    <div className="inner">
                                        <h4 className="title"> Welcome To LearnWithMurad </h4>
                                        <p>I am a Web Developer based in Sylhet, Bangladesh. I am also Proprietor of "Mobile Station" a business establishment of all kinds of mobile phone accessories, sale and servicing. Even though I have a Diploma Course (DICT-Diploma in Information and Communication Technology)and a B.A Course, coding is my passion and my new professional career focus. Web Development & Android apps Development has become my dream. I have Build Learnwithmurad platform to learn something good and serving humanity may it evolve into goodness a hundred thoughts.</p>
                                        <ul className="contact-address">
                                           {/* <li><FiFile /> Web designer & developer</li>*/}
                                            <li><FiPhone /> +8801717414414</li>
                                            <li><FiMapPin /> 38, Karimullah Market, 2nd Floor, Bondorbazar, Sylhet, Bangladesh.</li>
                                        </ul>
                                        {/*<div className="download-button mt--20">
                                            <a className="btn-read-more" href="#"><span>Download My CV</span></a>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutFive;